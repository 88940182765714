.information {
    width: 100%;
}

.information-text {
    display: flex;
    align-items: start;
    padding: 5px;
    font-size: 14px;
    margin: 0;
    font-family: "RB-r" !important;
}


.step-title {
    padding-right: 5px;
    padding-bottom: 3px;
    margin: 7px;
    border-right: 3px solid var(--color-3);
    font-family: "RB-b" !important;
    font-size: 16px;
    position: relative;
    width: 100% ;
}


.information-text p {
    color: white;
    background-color: var(--first-color);
    padding-bottom: 5px;
    max-width: 20px;
      min-width: 20px;
    height: 20px;
    text-align: center;
    margin: 2px;
    margin-left: 5px;
    border-radius: 2px;
    font-size: 13px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: var(--second-color);
    border-color: var(--second-color);
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: var(--first-color);
    border-color: var(--first-color);
}

.ant-steps-finish-icon svg path {
    fill: #fff
}

.survey-btn {
    background-color: var(--first-color) !important;
    border-color: var(--first-color) !important;
    color: #fff !important;
    font-family: "RB-r" !important;
}

label {
    font-size: 16px !important;
    margin-bottom: 2px;
}


.optional {
    position: relative;
}

.optional:after {
    content: '*';
    font-size: 12px;
    padding: 2px;
    color: #f00;
    position: absolute;
    top: -7px;
    left: 0px;

}

.btn-close {
    position: absolute;
    left: 5px;
}

.survey-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffffc4;
    overflow: hidden;
    height: 100%;
    z-index: -1;
}

.survey-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.1;
    z-index: -1;
}

.ant-form-item-extra {
    font-size: 14px !important;
    font-family: "RB-r" !important;
}

@keyframes AnimateBG {
    0% {
        background-position: 300vh 0%
    }

    100% {
        background-position: 0% 0%
    }
}

.image-move {
    background-image: url('../img/home/bg_sec.png');
    background-position: center top;
    background-repeat: repeat-x;
    background-size: 100% 150vh;
    background-size: contain;
    animation: AnimateBG 50s linear infinite alternate both;
}