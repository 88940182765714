.section-one{
    margin-top: 30px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   
}
.section-one__cover{
 background-color: #D3E9F8;
width: 95%;
min-height: 550px;
padding: 25px 5px ;
border-radius: 25px;
overflow: hidden;
position: relative;

}
.section-one__cover:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
 background-image: url('../img/home/bg_sec.png');
  background-position: 50% 0;
  background-size: contain;
  z-index: 1;
}
.section-one__image{
    width: 100%;
    height:100%;
    min-height: 550px;
    object-fit: cover;
    border-radius: 25px;
    padding: 10px 5px 5px 5px;
    z-index: 2;
}

.section-one__logo{
    width: 150px;
    margin-bottom: 30px;
}
.section-one__title{
   font-size: 25px;
    margin-bottom: 25px;
    text-align: center;
       z-index: 2;
}

.section-one__btn{
    background: var(--second-color);
  color: #fff;
  display: inline-block;
  padding: 12px 45px;
  border: 0;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  z-index: 1;
  position: relative;
  font-size: 15px;
  font-weight: 700;
}
.section-one__btn:hover{
  color: var(--first-color);
}

.section-one__more{
    background: var(--second-color);
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  border: 0;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  z-index: 1;
  position: relative;
  font-size: 15px;
  font-weight: 700;
}
.section-one__more:hover{
  color: var(--first-color);
}