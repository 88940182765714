:root {
  --first-color: #25A2DC;
  --second-color: #F89C42;
  --color-3: #25A2DC;
  --color-4: #5055A5;
}


@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
  }
}



@font-face {
  font-family: 'RB-b';
  src: url('../font/AbdElRady/NotoKufiArabic-Bold.woff2') format('woff2');
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'RB-r';
  src: url('../font/AbdElRady/NotoKufiArabic-Regular.woff2') format('woff2');
  font-style: normal;
  font-display: auto;
}

a {
  text-decoration: none;
  color: #49b5e7;
}

a:hover {
  color: #76c7ed;
  text-decoration: none;
}
.scrollto{
  font-size: 14px !important;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #49b5e7;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6dc4ec;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "RB-r";
}

#header .logo a {
  color: #0f394c;
}

#header .logo img {
  max-height: 40px;
}

#sidebar {
  display: none;
}

.sidebar {

  width: 250px;
  background-color: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  right: -250px;
  transition: right 0.3s ease-in-out;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
}

.sidebar ul li a {
  color: #000;
  text-decoration: none;
  display: block;
}

.content {
  margin-right: 250px;
  /* تمديد المحتوى ليتناسب مع الشريط الجانبي */
  padding: 20px;
}

/* تنشيط الشريط الجانبي عند الضغط على زر القائمة */
.active {
  right: 0;
}

/* CSS for sidebar view */
@media (max-width: 768px) {

  /* إخفاء القائمة الرئيسية */
  #navbar ul {
    display: none;
  }

  /* عرض القائمة المنسدلة عند النقر على الزر */
  #navbar .mobile-nav-toggle {
    display: block;
    cursor: pointer;
  }

  /* تعيين أنماط الشريط الجانبي */
  #sidebar {
    display: block;

  }

}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 30px;
  font-size: 15px;
  color: #0f394c;
  white-space: nowrap;
  transition: 0.3s;
  text-transform: uppercase;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #49b5e7;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 10px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 500;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #49b5e7;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .image-move {
    background-size: cover !important;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #0f394c;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 750px) {

  .navbar ul {
    display: none;
  }

  .image-move {
    background-size: cover !important;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(7, 25, 33, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0f394c;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #49b5e7;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #49b5e7;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
.hero {
  width: 100%;
}

.hero .container {
  padding-top: 84px;
}

.hero h1 {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  color: #0f394c;
  text-transform: uppercase;
}

.hero h2 {
  color: #1c698c;
  margin: 10px 0 0 0;
  font-size: 20px;
}

.hero .btn-get-started {
  font-family: "RB-r";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 3px;
  transition: 0.5s;
  margin-top: 25px;
  color: #fff;
  background: #49b5e7;
  text-transform: uppercase;
}

.hero .btn-get-started:hover {
  background: #76c7ed;
}

@media (max-width: 991px) {
  .hero {
    height: 100vh;
    text-align: center;
  }

  .image-move {
    background-size: cover;
  }

  .hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }



  .hero h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f4fbfe;
}

.section-title {
  text-align: center;
   font-weight: 700;
}

.section-title h2 {
  font-size: 32px;
   font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #0f394c;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about .icon-boxes .title {
  font-size: 25px;
  color: #0f394c;
  margin-bottom: 15px;
}

.heartbeat {
  -webkit-animation: heartbeat 5s ease-in-out infinite alternate both;
  animation: heartbeat 5s ease-in-out infinite alternate both;
}


.about .icon-box {
  margin-top: 40px;
}

.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #d0ecf9;
  border-radius: 50px;
  transition: 0.5s;
}

.about .icon-box .icon i {
  color: var(--first-color);
  font-size: 32px;
}

.about .icon-box:hover .icon {
  background: var(--second-color);
  border-color: var(--second-color);
}

.about .icon-box:hover .icon i {
  color: #fff;
}

.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.about .icon-box .title h2 {
  font-size: 35px;
}

.about .icon-box .title a {
  color: #343a40;
  transition: 0.3s;
}

.about .icon-box .title a:hover {
  color: #49b5e7;
}

.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 14px;
}

.about .video-box {
  background: url("../img/home/thum_vid.jpg") center center no-repeat;
  background-size: cover;
  min-height: 500px;
}

@media (min-width: 1200px) {
  .about .video-box {
    margin-left: 15px;
    margin-right: -15px;
  }


}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--first-color) 50%, rgb(12, 118, 118, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgb(12, 118, 118, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #0c7676;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
  background-size: cover;
  padding: 40px 0 60px 0;
  position: relative;
}

.counts::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.counts .title {
  position: relative;
  color: #0f394c;
  margin-bottom: 40px;
}

.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}

.counts .counters span {
  font-size: 44px;
  font-weight: 700;
  display: block;
  color: #49b5e7;
  font-family: "RB-r", sans-serif;
}

.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 15px;
  color: #444444;
}

@media (min-width: 1200px) {
  .counts {
    background-attachment: fixed;
  }
}

/*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
.clients .clients-wrap {
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}

.clients .client-logo {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  background: #fff;
  height: 120px;
}

.clients .client-logo img {
  height: 50%;
  filter: grayscale(100%);
  transition: 0.3s;
}

.clients .client-logo:hover img {
  filter: none;
  transform: scale(1.2);
}

.clients img {
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
}

.services .icon-box:hover {
  transform: translateY(-5px);
}

.services .icon {
  position: absolute;
  left: -20px;
  top: calc(50% - 30px);
}

.services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}

.services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .icon-box:hover .title a {
  color: #49b5e7;
}

.services .description {
  font-size: 14px;
  margin-left: 40px;
  line-height: 24px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 10px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #49b5e7;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #bdc6ca;
  border-left: 3px solid #bdc6ca;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #bdc6ca;
  border-right: 3px solid #bdc6ca;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #0f394c;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #0f394c;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #49b5e7;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #8dd0f0;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #49b5e7;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #49b5e7;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(15, 57, 76, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../img/home/bg_sec.jpg") no-repeat;
  background-position: center left;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image:linear-gradient(to right,#5055A5,#25A2DC) ;
  opacity: 0.8;
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item p {
  font-size: 22px;
  color: #ddd;
  margin: 0 0 15px 0;
  font-style: normal;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #49b5e7;
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }


}

/*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #0f394c;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(73, 181, 231, 0.8);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #76c7ed;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #0f394c;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
  # Gallery
  --------------------------------------------------------------*/
.gallery .gallery-item {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
  object-fit: cover;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #49b5e7;
  float: left;
  width: 44px;
  height: 44px;
  background: #ebf7fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #0f394c;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #2079a1;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #49b5e7;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #49b5e7;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #1da2e0;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f0f9fd;
  margin-top: 84px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 74px;
  }

  .image-move {
    background-size: cover !important;
  }
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
  font-family: "RB-r", sans-serif;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #175977;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .image-move {
    background-size: cover !important;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.site-footer
{
  background-color:var(--first-color);
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#ffff;
}
.site-footer hr
{
  border-top-color:#fff;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:var(--second-color);
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#fff
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:var(--second-color);
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:var(--second-color)
}
.copyright-text
{
  margin:0
}
@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding: 0 5px;
  list-style:none
}

.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#ffff;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons-2 a{
  display: flex;
  justify-content: center;
  align-items: center;
    background-color:var(--second-color);
  color:#ffff;
  font-size:20px;
  line-height:44px;
  width:50px;
  height:50px;
  text-align:center;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#1877f2
}
.social-icons a.tiktok:hover
{
  background-color:#000
}
.social-icons a.telegram:hover
{
  background-color:#0088cc
}
.social-icons a.instagram:hover
{
  background-color:#f46f30
}
.social-icons a.youtube:hover
{
  background-color:#ff0000
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}
.mask  {
    -webkit-mask-image:url(../img/home/blob.png);
    -webkit-mask-position:center center;
    -webkit-mask-repeat:no-repeat;
    -webkit-mask-size:contain;
  
    mask-image:url(../img/home/blob.png);
    mask-position:center center;
    mask-repeat:no-repeat;
    mask-size:contain;
}
.mask-2  {
    -webkit-mask-image:url(../img/home/blob2.png);
    -webkit-mask-position:center center;
    -webkit-mask-repeat:no-repeat;
    -webkit-mask-size:contain;
  
    mask-image:url(../img/home/blob2.png);
    mask-position:center center;
    mask-repeat:no-repeat;
    mask-size:contain;
}
.ul-list li::marker{
color: var(--second-color);
}